import IconButton from '@material-ui/core/IconButton';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import clsx from 'clsx';
import React, { useEffect, useMemo, useRef } from 'react';
import calculatorDesktop from '../../images/calculator-desktop.svg';
import calculatorMobile from '../../images/calculator-mobile.svg';
import calculatorTablet from '../../images/calculator-tablet.svg';
import closeBtn from '../../images/close.svg';
import ModalContainer from '../../styles/Components/Modal/ModalStyles';
import Link from '../../utils/Link';
import OptimizedImage from '../../utils/OptimizedImage';

const PopUpROI = ({ show, onClose }) => {
  const modalRef = useRef(null);
  const isTablet = useMediaQuery('(max-width: 991px)');
  const isMobile = useMediaQuery('(max-width: 768px)');

  useEffect(() => {
    if (show) {
      modalRef.current.classList.add('visible');
    } else {
      modalRef.current.classList.remove('visible');
    }
  }, [show]);

  const backgroundImage = useMemo(() => {
    if (isMobile) {
      return calculatorMobile;
    }
    if (isTablet) {
      return calculatorTablet;
    }
    return calculatorDesktop;
  }, [isMobile, isTablet]);

  return (
    <ModalContainer className={clsx(!show && 'hide-modal')}>
      <div ref={modalRef} className="modal">
        <div className="popup d-flex justify-content-end position-relative ">
          <div className="modal-wrapper">
            <IconButton className="close_btn" onClick={onClose}>
              <OptimizedImage src={closeBtn} alt="Close Button" />
            </IconButton>
            <div className="roi-content">
              <OptimizedImage src={backgroundImage} alt="ROI Content" />
              <div className="cta-wrapper">
                <p className="kicker">Free tool</p>
                <h5 className="heading">
                  Calculate your return on investment.
                </h5>
                <p className="sub-head">
                  Check out our new ROI calculator, based on other shops that
                  have chosen Shopmonkey as their shop management software.
                </p>
                <Link
                  to="/business-valuation-calculator"
                  className="btn btn-primary cta-roi"
                >
                  Calculate ROI
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ModalContainer>
  );
};

export default PopUpROI;
